export default {
  methods: {
    format_price(value, decimales = 0) {
      let val = (value / 1).toFixed(decimales).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    costFormat(value) {
      if (!value) { return "$0"; }
      if (value == "Pendiente") { return value; }
      else {
        return "$" + this.format_price(value, 2);
      }
    },
    costFormatNoCents(value) {
      if (!value) { return "$0"; }
      if (value === "Pendiente") { return value; }
      else return "$" + this.format_price(value);
    },
    prcgFormat(value, decimales = 0) {
      if (!value) { return "0%"; }
      if (value == "Pendiente") { return value; }
      else { return value.toFixed(decimales) + "%"; }
    },
    decimalsFormat(value, decimales = 0) {
      if (!value) { return 0; }
      if (value == "Pendiente") { return 0; }
      else { return value.toFixed(decimales); }
    },
    explicitMoneyFormat(value, decimales = 2) {
      if (value == null) return "";
      else {
        return "$" + this.format_price(value, decimales);
      }
    },
    mxnFormat(value, decimales = 0) {
      if (!value) { return "$0MXN"; }
      else {
        return "$" + this.format_price(parseInt(value), decimales) + " MXN";
      }
    },
    moneyFormat(value) {
      if (!value) { return "$0"; }
      else {
        return "$" + this.format_price(parseInt(value), 0);
      }
    },
    decimalPercentFormat(value, decimales = 2) {
      if (!value) return "0%";
      else {
        return (value * 100).toFixed(decimales) + "%";
      }
    },
    roundedFormat(value, decimales = 2) {
      if (!value) { return 0; }
      else {
        let c = value.toFixed(decimales);
        let cd = Math.round(c);
        return cd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    phoneFormat(value) {
      if (!value) return value
      else {
        return '(' + value.substr(0, 2) + ') ' + value.substr(2, 4) + '-' + value.substr(6, 4)
      }
    },
    phoneFormatV2(value) {
      if (!value) return value
      else {
        return '(' + value.substr(0, 3) + ') ' + value.substr(2, 3) + ' ' + value.substr(6, 4)
      }
    },
    visibleLastNChars(value, chars = 4) {
      if (!value) return value
      else {
        let valueString = value.toString();
        if (chars > valueString.length || chars < 0) return value
        else {
          let phoneLength = valueString.length;
          const firstChars = "*".repeat(phoneLength - chars);
          const last4Digits = valueString.slice(firstChars.length);
          return firstChars + last4Digits;
        }
      }
    },
    capitalizeFirstLetter(value) {
      if (!value) return value
      else {
        const arr = value.split(" ");
        for (let i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

        }

        return arr.join(" ");
      }
    },
    //Función para validar un RFC
    isValidRfcFormat(rfc) {
      if(rfc) {
        const patternPM = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
          "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
          "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
          "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
        const patternPF = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
          "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
          "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
          "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
        return rfc.match(patternPM) || rfc.match(patternPF);
      } else {
        return false;
      }
    },
    //Función para validar un RFC por tipo de persona
    isValidRfcFormatByPersonType(rfc, isMoral) {
      if(rfc) {
        // Formato para PF
        let pattern = /^[A-Z]{4}(\d{2})((0[13578]|1[02])([0-2]\d|3[01])|(0[469]|11)([0-2]\d|30)|(02)([0-2]\d))([A-Z0-9]{3})$/;
        let genericRFC1 = "XAXX010101000";
        let genericRFC2 = "XEXX010101000";
        if(isMoral) {
          // Formato para PM
          pattern = /^[A-Z]{3}(\d{2})((0[13578]|1[02])([0-2]\d|3[01])|(0[469]|11)([0-2]\d|30)|(02)([0-2]\d))([A-Z0-9]{3})$/;
        }

        return rfc.match(pattern) &&  rfc != genericRFC1 && rfc != genericRFC2;
      } else {
        return false;
      }
    },
    //Función para validar una CURP
    isValidCurpFormat(curp) {
      if(curp) {
        const pattern = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
        let isValid = curp.match(pattern);
        if (!isValid)  //Coincide con el formato general?
          return false;
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        let diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
        let lngSuma = 0.0;
        let lngDigito = 0.0;
        for (let i = 0; i < 17; i++) {
          lngSuma = lngSuma + diccionario.indexOf(isValid[1].charAt(i)) * (18 - i);
        }
        lngDigito = 10 - lngSuma % 10;
        let verifierDigit = (lngDigito != 10) ? lngDigito : 0;
        return isValid[2] == verifierDigit;
      } else {
        return false;
      }
    },
    anonymousFormatPhone(value){
      if (value === "") return value
      else {
        return value.replace(/^(?:\D*\d){8}/g, "********");
      }
    },
    genderTranslate(value) {
      return value == 1 ? "Hombre" : "Mujer";
    }
  }
}