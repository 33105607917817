export default {
  methods: {
    reg(value, caps = false, trim = false){
      if(value == null || value == ''){
        return "";
      }

      if(caps){
        return this.upper(value, trim);
      }
      else if(trim) {
        return this.trimSpaces(value);
      }
      else {
        return value;
      }
    },
    upper(value, trim = false) {
      if (value == null || value == '') {
        return "";
      }
      return this.cleanTildes(value, trim).toUpperCase();
    },
    cleanTildes(s, trim = false){
      var r = s.toLowerCase();
      r = r.replace(new RegExp("[àáâãäå]", 'g'),"a");
      r = r.replace(new RegExp("æ", 'g'),"ae");
      r = r.replace(new RegExp("ç", 'g'),"c");
      r = r.replace(new RegExp("[èéêë]", 'g'),"e");
      r = r.replace(new RegExp("[ìíîï]", 'g'),"i");
      //r = r.replace(new RegExp("ñ", 'g'),"n");                            
      r = r.replace(new RegExp("[òóôõö]", 'g'),"o");
      r = r.replace(new RegExp("œ", 'g'),"oe");
      //r = r.replace(new RegExp("[ùúûü]", 'g'),"u");
      r = r.replace(new RegExp("[ùúû]", 'g'),"u");
      r = r.replace(new RegExp("[ýÿ]", 'g'),"y");
      
      if(trim){
        r = r.replace(/\s/g,'');
      }

      return r;
    },
    trimSpaces(s){
      return s.replace(/\s/g,'');
    },
    uppercase(value){
      if (value == null || value == '') {
        return "";
      }
      return value.toUpperCase();
    }
  }
}