export default {
  methods: {
      async setZipCodeAddressValues(addressId, address, {zipCodeVariable, statesArray, citiesArray, neighborhoodsArray}) {
        if(!addressId)
        {  
          this[zipCodeVariable] = '';
          return;
        }
        let result = await this.publicApiGet(`api/getAddressById/${addressId}`);
        if(result) {
          result = result.data;
          this[zipCodeVariable] = result.address.zipcode;
          if(result.address.zipcode != null) {
            this.collections[statesArray] = [result.state];
            this.collections[citiesArray] = [result.city];
            this.collections[neighborhoodsArray] = result.neighborhoods;
            
            address.state_id = result.neighborhoods[0].city.state_id;
            address.city_id = result.neighborhoods[0].city_id;
            address.neighborhood_id = result.address.neighborhood_id;
          }
        }
      },
      async setPayloadZipCodeAddressValues(addressId, payload, {addressName, statesArray, citiesArray, neighborhoodsArray}) {
        if(!addressId)
        {  
          this[addressName] = '';
          return;
        }
        let result = await axios.get(`api/getAddressById/${addressId}`);
        if(result) {
          result = result.data;
          if(result.address.zipcode != null) {
            this[addressName] = result.address.zipcode;
            this.collections[statesArray] = [result.state];
            this.collections[citiesArray] = [result.city];
            this.collections[neighborhoodsArray] = result.neighborhoods;
            
            payload[`${addressName}$state_id`] = result.neighborhoods[0].city.state_id;
            payload[`${addressName}$city_id`] = result.neighborhoods[0].city_id;
            payload[`${addressName}$neighborhood_id`] = result.address.neighborhood_id;
          }
        }
      },

		verifyPayloadZipCode(payload, {addressName, statesArray, citiesArray, neighborhoodsArray, addressId}) {
			if(this[addressName] == payload[`${addressName}$zipcode`])
				return;
			this.clearPayloadAddressFields({payload, addressName, statesArray, citiesArray, neighborhoodsArray});
			if(payload[`${addressName}$country_id`] == this.mexicoCountryId) // México
      {
        let zipCode = payload[`${addressName}$zipcode`];
        if(zipCode && zipCode.length >= 5) {
          if(this.verifyZipCodeTimer != null) 
            clearTimeout(this.verifyZipCodeTimer);
          this.verifyZipCodeTimer = setTimeout(() => this.getPayloadAddressByZipCode({payload, addressName, statesArray, citiesArray, neighborhoodsArray, addressId}), 1000);
        }
      }
		},
		async getPayloadAddressByZipCode({payload, addressName, statesArray, citiesArray, neighborhoodsArray, addressId}) {
      try {
        if(isNaN(payload[`${addressName}$zipcode`])) {
					this.warningNotif('Código postal', 'El código postal ingresado no es válido');
          return;
        }

        if(addressId && !this[addressName] && payload[`${addressName}$neighborhood_id`] != null) {
          this.setPayloadZipCodeAddressValues(addressId, payload, {
            addressName,
            statesArray, 
            citiesArray, 
            neighborhoodsArray
          });
          return;
        }
  
        let result = await axios.get(`api/getAddressByZipCode/${payload[`${addressName}$zipcode`]}`);
        if(result) {
          result = result.data;
          if(result.neighborhoods.length > 0) {
            this[addressName] = payload[`${addressName}$zipcode`];
            this.collections[statesArray] = [result.state];
            this.collections[citiesArray] = [result.city];
            this.collections[neighborhoodsArray] = result.neighborhoods;
            payload[`${addressName}$state_id`] = result.state.id;
            payload[`${addressName}$city_id`] = result.city.id;
            payload[`${addressName}$neighborhood_id`] = null;
            return;
          }
          this.warningNotif('Código postal', 'El código postal no tuvo resultados');
        }
      } 
      catch (e) {
        this.warn(e);
        this.failed = true;
      }	
    },
		clearPayloadAddressFields({payload, addressName, statesArray, citiesArray, neighborhoodsArray}) {
			payload[`${addressName}$state_id`] = null;
			payload[`${addressName}$city_id`] = null;
			payload[`${addressName}$neighborhood_id`] = null;
  
      this.collections[statesArray] = [];
			this.collections[citiesArray] = [];
			this.collections[neighborhoodsArray] = [];
    },

    verifyZipCode(address, {zipCodeVariable, statesArray, citiesArray, neighborhoodsArray}) {
      if(this[zipCodeVariable] === undefined || this[zipCodeVariable] == address.zipcode)
        return;
      this.clearAddressFields({address, statesArray, citiesArray, neighborhoodsArray});
      if(address.zipcode)
        this[zipCodeVariable] = address.zipcode;
      if(address.country_id == this.mexicoCountryId) // México
      {
        let zipCode = address.zipcode;
        if(zipCode && zipCode.length >= 5) {
          if(this.verifyZipCodeTimer != null) 
            clearTimeout(this.verifyZipCodeTimer);
          this.verifyZipCodeTimer = setTimeout(() => this.getAddressByZipCode({address, statesArray, citiesArray, neighborhoodsArray}), 1000);
        }
      }
	},			
    async getAddressByZipCode({address, statesArray, citiesArray, neighborhoodsArray}) {
      try {
        if(isNaN(address.zipcode)) {
          this.warningNotif('Código postal', 'El código postal ingresado no es válido');
          return;
        }
  
        let result = await this.publicApiGet(`api/getAddressByZipCode/${address.zipcode}`);
        if(result) {
          result = result.data;
          if(result.neighborhoods.length > 0) {
            this.collections[statesArray] = [result.state];
            this.collections[citiesArray] = [result.city];
            this.collections[neighborhoodsArray] = result.neighborhoods;
            address.state_id = result.state.id;
            address.city_id = result.city.id;
            address.neighborhood_id = null;
            return;
          }
          this.warningNotif('Código postal', 'El código postal no tuvo resultados');
        }
      } 
      catch (e) {
        this.warn(e);
        this.failed = true;
      }	
    },
    clearAddressFields({address, statesArray, citiesArray, neighborhoodsArray}) {
      address.state_id = null;
      address.city_id = null;
      address.neighborhood_id = null;
  
      this.collections[statesArray] = [];
			this.collections[citiesArray] = [];
			this.collections[neighborhoodsArray] = [];
    },
    isAutoFormField(f){
      return f.special_definition == 0 && f.doc_id == null && f.field_type != 'document';
    },
    isAutoFormFieldPublic(f){
      return f.special_definition == 0 && f.doc_id == null && f.field_type != 'document' && f.bo_private != 1;
    },
    isDocumentNotPrivate(f){
      return f.bo_private == 0 && (f.doc_id != null && f.field_type == 'document');
    },
    notDocumentNorPrivate(f){
      return f.bo_private == 0 && 
      (f.doc_id == null && f.field_type != 'document');
    },
    isAutoFieldToSave(f){
      return this.isAutoFormField(f) && !f.is_readonly;
    },
    formFieldSetter(f, parent, useNested = false){
      f.fname = f.db_field;

       if(f.max_chars > 0 || f.min_chars > 0){
        // definir que se requiere trim
        f.trim = true;
      }

      if(f.extras == null) return;
      let extras = JSON.parse(f.extras);
      f.parsed_extras = extras;

      // check if need to be aware of change
      if(extras.on_change != null){
        f.on_change_method = extras.on_change;

        if(!(extras.on_change in parent)){
          this.warn(`METHOD IMPLEMENTATION MISSING [${f.fname}]: ${extras.on_change}`);
        }
      }

      // check if need to be aware of change
      if(extras.on_mounted != null){
        f.on_mounted = extras.on_mounted;

        if(!(extras.on_mounted in parent)){
          this.warn(`METHOD IMPLEMENTATION MISSING [${f.fname}]: ${extras.on_mounted}`);
        }
      }

      // check if need to be aware of change
      if(extras.special_validation != null){
        f.special_validation = extras.special_validation;
        if(!(f.special_validation.method in parent)){
          this.warn(`SPECIAL VALIDATION IMPLEMENTATION MISSING [${f.fname}][${f.section_id}]: ${f.special_validation.method}`);
        }
      }

      if(extras.hard_validation != null){
        f.hard_validation = extras.hard_validation;
        if(!(f.hard_validation.method in parent)){
          this.warn(`HARD VALIDATION IMPLEMENTATION MISSING [${f.fname}][${f.section_id}]: ${f.hard_validation.method}`);
        }
      }

      // check if need to be show_conditioned
      if(extras.show_only_if != null){
        f.show_conditioned = true;
        f.condition = extras.show_only_if;

        if(!(extras.show_only_if in parent)){
          this.warn(`CONDITION IMPLEMENTATION MISSING [${f.fname}]: ${extras.show_only_if}`);
        }
      }

      // check if need to be a specific value
      if(extras.exact_value != null){
        f.exact_value = extras.exact_value;
        //f.disabled = true;
      }

      // check if need to be a specific value
      if(extras.is_readonly != null){
        f.is_readonly = extras.is_readonly;
        f.disabled = true;
      }

      if(extras.is_computed != null){
        f.is_computed = extras.is_computed;
      }

      if(extras.external_computed != null){
        f.external_computed = extras.external_computed;
      }

      if(extras.disabled != null){
        f.disabled = extras.disabled;
      }

      if(extras.placeholder != null){
        f.placeholder = extras.placeholder;
      }
      if(extras.disabled_from_today != null){
        f.disabled_from_today = extras.disabled_from_today;
      }

       // check if edition need to be conditioned
      if(extras.disabled_if != null){
        f.disabled_conditioned = true;
        f.disabled_condition = extras.disabled_if;

        if(!(extras.disabled_if in parent)){
          this.warn(`CONDITION IMPLEMENTATION MISSING [${f.fname}]: ${extras.disabled_if}`);
        }
      }

      // check for alias
      if(extras.alias != null){
        f.fname = extras.alias;
      }

      // check prefix for nested values
      if(extras.nested != null && useNested){
        f.fname = extras.nested + "$" + f.fname;
      }

      // check prefix for div_class key
      if(extras.div_class != null){
        f.div_class = extras.div_class;
      }

      // check for extended textarea
      if(extras.textarea_extended != null){
        f.textarea_extended = extras.textarea_extended;
      }

      // check if should use input group
      if(extras.input_group != null){
        f.input_group = extras.input_group;
        if(!(f.input_group.source in parent)){
          this.warn(`INPUT GROUP SOURCE IMPLEMENTATION MISSING [${f.fname}]: ${f.input_group.source}`);
        }
      }
    },
    findDocSetsForField(formField, section){
      return section.documents.find(f => f.id == formField.doc_id);
    },
    nestedFieldValue(main, field){
      if(field == null){
        return null;
      }
      else if(field == "base"){
        return main;
      }
      else {
        let val = this.getNestedObject(main, this.arrayFromFieldName(field) );
        return val;
      }
    },
    getNestedObject(nestedObj, pathArr) {
      if(pathArr.length == 0){ return nestedObj}
      return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    },
    setDeepObject(target, path, value) {
      let pathArr = this.arrayFromFieldName(path);
      pathArr.reduce((obj, key, level) => {
        if(level == pathArr.length -1){
          obj[key] = value;
          return value;
        }
        if(obj[key] == undefined){
          obj[key] = {};
          return obj[key];
        }
        return obj[key];
      }, target);
    },
    setDeepObjectWithIds(target, source, path, value){
      let pathArr = this.arrayFromFieldName(path);
      let nestedPath = [];
      pathArr.reduce((obj, key, level) => {        
        if(level == pathArr.length -1){
          obj[key] = value;
          obj.id = this.getNestedObject(source, nestedPath).id;
          return value;
        }
        if(obj[key] == undefined){
          obj[key] = {};
          obj.id = this.getNestedObject(source, nestedPath).id;
          nestedPath.push(key);
          return obj[key];
        }
        nestedPath.push(key);
        return obj[key];
      }, target);
    },
    setNestedObjectWithIds(target, source, path, value){
      let pathArr = this.arrayFromFieldName(path);
      let nestedPath = [];
      let parent = target;
      pathArr.forEach(key => {
        if(pathArr.length > 1){
          parent = this.getNestedObject(target, nestedPath);
        }
        nestedPath.push(key);
        let obj = this.getNestedObject(target, nestedPath);
        let nidObj = this.getNestedObject(source, nestedPath);
        let nid = null;
        if(nidObj){
          nid = nidObj.id;
        }
        if(nestedPath.join(".") == path){
          if(!obj){
            obj = value;
            obj.id = nid;
          }
          else {
            this.copyKeysToObject(obj, value);
            obj.id = nid;
          }
        }
        else{
          if(!obj){
            obj = {id: nid};
          }
          else {
            obj.id = nid;
          }
        }
        parent[key] = obj;
      });
    },
    copyKeysToObject(recipient, origin){
      let k;
      for (k in origin) {
        recipient[k] = origin[k];
      }
    },
    arrayFromFieldName(fname){
      return fname.split('.');
    },
    objectIsEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    unpackNested(data){
      let base = {};
      for(var prop in data){
        if(typeof data[prop] == "object"){
          for (var pr in data[prop]) {
            base[prop + "$" + pr] = data[prop][pr];
          }
        }
        else {
          base[prop] = data[prop];
        }
      }
      return base;
    },
    packNested(base){
      let data = {};
      for(var prop in base){
        if(prop.includes("$")){
          let r = prop.split("$");
          let k = r[0];
          let v = r[1];
          if(data[k] == null){
            data[k] = {};
          }
          data[k][v]= base[prop];
        }
        else {
          data[prop] = base[prop];
        }
      }
      return data;
    },
    async validate_unique_curp(curp, personal_id){
      try {
        let p = {curp: curp, current_personal_profile_id: personal_id};
        let r = await this.publicApiPost('api/v1/personal/verify_unique_curp', p);
        return r.data == "valid";
      }
      catch (error) {
        return false;
      }
    },
    async validate_clabe(clabe, banks){
      if(clabe.length >= 3){
        let g = clabe.slice(0, 3);
        if(banks.find(f => f.clabe_code == g)){
          return true;
        }
      }
      return false;
    },
    async validate_unique_personal_rfc(rfc, personal_id){
      try {
        let validation = [];

        let p = {rfc: rfc, current_personal_profile_id: personal_id};
        let r = await axios.post('api/v1/personal/verify_unique_rfc', p);
        if (r.data !== "valid") {
          validation.push({n: "RFC ya registrado", e: "Ya tenemos un registro con el mismo RFC, te pedimos de favor nos contactes a través del Chat para revisar el caso."});
        }

        let p2 = {rfc: rfc};
        let r2 = await axios.post('api/v1/personal/validate_rfc_format', p2);
        if (r2.data !== "valid") {
          validation.push({n: "RFC Formato Incorrecto", e: "El formato de RFC no coincide con el formato para una Persona Física."});
        }

        return validation;
      }
      catch (error) {
        return false;
      }
    },
    async validate_unique_business_rfc(rfc, business_id, just_uniqueness = false){
      try {
        let validation = [];
        let p = {rfc: rfc, current_business_profile_id: business_id};
        let r = await axios.post('api/v1/business/verify_unique_rfc', p);
        if (r.data !== "valid") {
          if(just_uniqueness) {
            return false;
          }
          validation.push({n: "RFC ya registrado", e: "Ya tenemos un registro con el mismo RFC, te pedimos de favor nos contactes a través del Chat para revisar el caso."});
        }

        let p2 = {rfc: rfc};
        let r2 = await axios.post('api/v1/business/validate_rfc_format', p2);
        if (r2.data !== "valid") {
          validation.push({n: "RFC Formato Incorrecto", e: "El formato de RFC no coincide con el formato para una Persona Moral."});
        }

        return validation;
      }
      catch (error) {
        return false;
      }
    },
    async isEmailEqualToApplicants(email, applicantUserId, relationData) {
      try {
        const {data} = await axios.post(`api/v1/email-equal-to-applicants/${applicantUserId}`, { email, relationData });
        return data.isEqual;
      }
      catch (error) {
        return false;
      }
    },
    async isEmailDuplicatedInProject(email, projectId, relationData) {
      try {
        const {data} = await axios.post(`api/v1/email-duplicated-in-project/${projectId}`, { email, relationData });
        return data.isEqual;
      }
      catch (error) {
        return false;
      }
    },
    evaluateConditionBase(parent, condition){
      if(condition != null && condition in parent){
        let ss = parent[condition];
      //  console.log("evaluateCondition => " + condition + ", " + ss);
        return ss;
      }
      else if(condition != null) {
        this.warn("Missing evaluator condition: " + condition);
      }
    },
    async evaluateFieldCompletition(f, container, evaluator, validator){
      if(container != null){
        let val = container[f.fname];
        if(f.show_conditioned){
          let inc = evaluator(f.condition);
          if(!inc){
            return null;
          }
        }
        if(f.field_type == "checkbox") { return null}
        let rules = "";
        if(f.is_required){ rules = "required";}
        let r = await validator.verify(val, rules);      
        return r.valid;      
      }
      return false;
    },
    async asyncForEach(array, callback){
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    /**
     * Envía una notificación a slack cuando el curp/rfc es duplicado
     * @param {
     *  user_id: number,
     *  loan_id: number,
     *  flow: string,
     *  role: string,
     *  id: string
     * } data 
     */
    async sendIdentifiersNotification(data)
    {
      this.missingFieldsNotif(null, `Ya existe un perfil asociado a este ${data.flow}. Te contactaremos por WhatsApp o correo electrónico en no más de 1 día hábil para brindarte apoyo.`);
      try {
        await this.publicApiPost('api/v1/profile/identifiers-notification', data);
      } catch (error) {
        console.log(error);
      }
    }
  }
}