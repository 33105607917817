<template>
  <div id="solicitante">
    <big-title>
      Mis <span>Referencias</span>.
      <p class="text-subtitle mt-4">
        En este apartado podrás registrar tus referencias que serán usadas posteriormente para la solicitud de créditos.
      </p>
    </big-title>

    <vx-card>
      <applicant-references-control :applicant_id="ApplicantId"/>
    </vx-card>
  </div>
</template>

<script>
import ApplicantReferencesControl from '@applicant/ApplicantReferencesControl';

export default {
  components: {
    ApplicantReferencesControl
  },
  data() {
    return {
      beneficiaries: 0,
      loaded: false,
    }
  },
  methods: {
  }
}
</script>