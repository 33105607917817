<template>
  <div class="vx-col w-full mb-5" >
    <vs-button v-if="!isDisabled" color="primary" type="border" icon-pack="feather" class="mb-4"  @click="addLocationTestimony()">Agregar referencia</vs-button>      
        
      <vs-table stripe noDataText="No se han generado registros" :data="testimonies">
        <template slot="thead">
          <vs-th class="bold">NOMBRE</vs-th>
          <vs-th class="bold">CORREO</vs-th>
          <vs-th class="bold">TELÉFONO</vs-th>
          <vs-th class="bold">TIPO DE RELACIÓN</vs-th>
          <vs-th class="bold">ACCIONES</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>

            <vs-td :data="data[indextr].email">
              {{ data[indextr].email }}
            </vs-td>

            <vs-td :data="data[indextr].phone_id">
              {{data[indextr].phone_id ? `${data[indextr].phone.country_calling_code} ${phoneFormat(data[indextr].phone.phone)}` : phoneFormat(data[indextr].phone)}}
            </vs-td>

            <vs-td :data="data[indextr].relation_type" class="table-wrap">
              {{ nameForRelationType(data[indextr].relation_type) }}
            </vs-td>

            <vs-td :data="data[indextr].id">
              <feather-icon v-if="!isDisabled" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="removeItemRequest(data[indextr])" />
              <p v-else>Sin acciones disponibles</p>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup
        title="Agregar referencia"
        :active.sync="showFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>
          <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
            <span>{{ successMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{formPromptIndication}}</p>

          <vs-input
            class="w-full mb-4" 
            label="Nombre completo"
            placeholder="Requerido"
            v-validate="'required'"
            v-on="{input: e => {name = toUpperCase(e) }}"
            data-vv-name="name"
            :danger="hasError('name')"
            :danger-text="errorText('name')"
            :success="isSuccess('name')"
            v-model.lazy="name" /> 

            <vs-input
            class="w-full mb-4" 
            label="Correo"
            placeholder="Requerido"
            v-validate="'required|email'"
            data-vv-name="email"
            :danger="hasError('email')"
            :danger-text="errorText('email')"
            :success="isSuccess('email')"
            v-model.lazy="email" /> 
            
            <div class="flex">
              <vue-country-code
                name="dialCode"
                class="vs-custom dial-code"
                v-validate="'required'"
                data-vv-name="dialCode"
                :enableSearchField="true"
                searchPlaceholderText="Buscar código de país"
                :enabledCountryCode="true"
                @onSelect="onSelect"
                :defaultCountry="preferredCountries"
                :preferredCountries="['MX']"
                :danger="hasError('dialCode')"
                :danger-text="errorText('dialCode')"
                :success="isSuccess('dialCode')">
              </vue-country-code>

              <vs-input
              class="w-full mb-4" 
              label="Celular a 10 dígitos"
              placeholder="Requerido"
              v-validate="'required|digits:10'"
              type="tel"
              data-vv-name="phone"
              icon-pack="feather"
              icon="icon-phone"
              :danger="hasError('phone')"
              :danger-text="errorText('phone')"
              :success="isSuccess('phone')"
              v-model.lazy="phone" />

            </div>

          <!-- Campos tipo select -->
          <!-- <vx-select class="w-full mb-5" 
            label="Tipo de relación" 
            v-model.lazy="relation_type"
            name="relation_type"
            v-validate="'required'"
            :danger="hasError('relation_type')"
            :danger-text="errorText('relation_type')"
            :success="isSuccess('relation_type')"
            placeholder="Seleccione una opción" >
            <vx-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in getOptionsForSelect" class="w-full" />
          </vx-select> -->

          <label for="" class="vs-input--label">Tipo de Relación</label>
          <v-select
            label="text"
            name="relation_type"
            class="vs-custom w-full mb-5"
            v-model.lazy="relation_type"
            v-validate="'required'"
            :options="getOptionsForSelect"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="false"
            placeholder="Seleccione una opción"       
          >
          </v-select>
          <v-error v-if="hasError('relation_type')" :error="errorText('relation_type')" :success="isSuccess('relation_type')" />

          <vs-button :disabled="!completeForm || $isLoading" @click="saveTestimony" color="primary" class="mr-5 mt-2">Agregar</vs-button>
          <vs-button @click="closeFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>

    </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper"
import formHelper from '@components/mixins/formHelper';
import formatHelper from "@mixins/formatHelper";
const indicationDef = "Ingresa todos los datos solicitados para generar la referencia.";
const errorMssgDef = "Ha ocurrido un error al agregar la referencia, intente más tarde."
const successMssgDef = "La referencia ha sido agregada exitosamente"
const statePresenceVal = 0;
const cityPresenceVal = 1;

export default {
  name: 'applicant-references-control',
  mixins: [inputHelper, formHelper, formatHelper],
  props: {
    applicant_id: { type: [String,Number], required: true },
    isDisabled: { type: [String,Boolean], default: false },
    projectId: { type: [String,Number], default: false },
  },
  data(){
    return {
      name: null,
      phone: null,
      dialCode: null,
      preferredCountries: 'MX',
      relation_type: null,
      email: null,

      relationTypes: [],
      getOptionsForSelect: [],
      testimonies: [],

      showFormPrompt: false,
      selectedTestimony: null,

      onError: false,
      onSuccess: false,
      errorMssg: errorMssgDef,
      successMssg: successMssgDef,
      formPromptIndication: indicationDef,
    }
  },
  mounted: async function(){
    await this.getCollections();
    await this.loadSavedTestimonies();
  },
  computed: {
    completeForm(){
      return this.relation_type != null && this.name != null && this.phone != null && this.email != null;
    }
  },
  methods: {
    onSelect({ dialCode }) {
      this.dialCode = `+${dialCode}`;
    },
    async getCollections(){
      try {
        let res = await axios.get("/api/v1/forms/getProjectRelatedProfilesRelationTypes");
        this.relationTypes = res.data;

        let options = [];
        this.relationTypes.forEach(opt => {
          options.push({value: opt.type, text: opt.name}); //conversion a sintaxis de vx-select 
        })
        this.getOptionsForSelect = options;
      } catch (error) {
        this.warn(error);
      }
    },
    async loadSavedTestimonies(){
      try {
        let params = "?with[]=references.phone";
        let response = await axios.get(`/api/v1/applicant/${this.applicant_id}${params}`);
        this.testimonies = response.data.references;//.filter(f => f.family_group_id == this.family_group_id);
      }
      catch (e) {
        this.warn(e);
      }      
    },
    nameForRelationType(type){
      let ff = this.relationTypes.find(f => f.type == type);
      if(ff){
        return ff.name;
      }
    },
    removeItemRequest(testimony){
      this.selectedTestimony = testimony;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar referencia',
        text: `¿Realmente desea eliminar la referencia con nombre ${testimony.name}?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveItem
      });
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {project_related_profile_id: this.selectedTestimony.id};
        await axios.post(`/api/v1/applicant/${this.applicant_id}/removeReference`, payload);
        await this.loadSavedTestimonies();
        this.$emit("updated", 1);
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    async saveTestimony(){
      this.onSuccess = false;
      this.onError = false;
      
      this.showLoading(true);
      try {
        const isEmailDuplicated = await this.isEmailDuplicatedInProject(this.email, this.$props.projectId);
        if(isEmailDuplicated) {
          this.errorMssg = 'El correo ya se encuentra definido en la proyecto, por favor define otro';
          this.onError = true;
          this.showLoading(false);
          return;
        }

        let payload = { 
          name: this.name
          , phone: this.phone
          , email: this.email
          , relation_type: this.relation_type
          , country_calling_code: this.dialCode
        };
        await axios.post(`/api/v1/applicant/${this.applicant_id}/addReference`, payload);
        this.onSuccess = true;
        await this.loadSavedTestimonies();
        this.closeFormPrompt();
        this.$emit("updated", 1);
      }
      catch (error) {
      console.log(error);
        this.warn(error);
        this.errorMssg = errorMssgDef;
        this.onError = true;
      }
      this.showLoading(false);
    },
    addLocationTestimony(){
      this.resetForm();
      this.showFormPrompt = true;
    },
    resetForm(){
      this.relation_type = null
      this.name = null
      this.phone = null
      this.email = null
      this.onError = null
      this.onSuccess = null      
      this.sent = null
      this.errorMssg = errorMssgDef;
    },
    closeFormPrompt() {
      this.showFormPrompt = false;
      this.resetForm();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);      
    },

    /* check validation */
    validate(){
      return this.testimonies.length >= 3;
    }
  }
}
</script>